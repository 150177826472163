import React from 'react'
import PropTypes from 'prop-types'
import './nlpfeature.css'

const Nlpfeature = ({ features, buttontext, rootClassName }) => {
  return (
    <div className={`nlpfeature-layout349 thq-section-padding ${rootClassName}`}>
      <div className="nlpfeature-max-width thq-grid-auto-300 thq-section-max-width">
        {features.map((feature, index) => (
          <div key={index} className="nlpfeature-container thq-flex-column thq-card">
            <img
              alt={feature.imageAlt}
              src={feature.imageSrc}
              className="nlpfeature-image thq-img-round thq-img-ratio-1-1"
            />
            <h2 className="nlp-heading thq-heading-2">{feature.title}</h2>
            <span className="nlpfeature-text thq-body-small">
              {feature.description}
            </span>
            <a
              href="{/courses}"
              className="nlpfeature-button thq-button-animated thq-button-outline"
            >
              <span className="nlpfeature-text thq-body-small">
                {buttontext}
              </span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

Nlpfeature.defaultProps = {
  features: [
    {
      title: 'Mind Mastery Blueprint',
      imageAlt: 'Image of mind blueprint',
      imageSrc: './Nlp1.png',
      description: 'Unlock your mind’s potential with strategies to improve focus, mental clarity, and cognitive strength.'
    },
    {
      title: 'Neuro Mastery',
      imageAlt: 'Image of Neuro Mastery',
      imageSrc: './Nlp2.png',
      description: 'Harness neuroplasticity to control thoughts and emotions, creating lasting mental and emotional transformation.'
    },
    {
      title: 'Transactional Mastery',
      imageAlt: 'Image of Transactional Mastery',
      imageSrc: './Nlp3.png',
      description: 'Gain confidence in interactions with tools to enhance communication, relationships, and personal influence.'
    },
    {
      title: 'Inner Light Activation',
      imageAlt: 'Image of Inner Light',
      imageSrc: './Nlp4.png',
      description: 'Elevate your energy, connect with your inner self, and ignite your personal power.'
    },
  ],
  buttontext: 'Learn Now',
  rootClassName: 'features211-root-class-name',
}

Nlpfeature.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      imageAlt: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  buttontext: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Nlpfeature
