import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Autoplay } from 'swiper'
import './hero.css'
// Install Swiper modules
SwiperCore.use([Autoplay])

const Hero = (props) => {
  return (
    <div className="hero-header5 thq-section-padding">
      <div className="hero-container01">
        <h1 className="thq-heading-1">{props.heading1} <br /> {props.heading2}</h1>
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 8000 }} // Change slide every 8 seconds
        loop={true} // Loop back to the start
        className="hero-swiper"
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="hero-slide">
              {slide.type === 'image' ? (
                <>
                  <img
                    alt={slide.alt}
                    src={slide.src}
                    className="hero-image"
                  />
                </>
              ) : ( 
                <video
                  src={slide.src}
                  className="hero-video"
                  autoPlay
                  muted
                  loop
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

Hero.defaultProps = {
  heading1: 'One-on-One Personalized Session',
  heading2: 'With Peak Performance Coach',
  slides: [
    {
      type: 'image',
      src: './main1.png',
      alt: 'First Image'
    },
    {
      type: 'video',
      src: './main1.mp4',
      alt: 'First Video'
    },
  ],
}

Hero.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['image', 'video']).isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
}

export default Hero
