import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar81 from '../components/navbar81'
import Hero from '../components/hero'
import Testimonialnpl from '../components/testimonialnpl'
import Message from '../components/message'
import Footer1 from '../components/footer1'
import FAQ  from '../components/faq11'
import Bonus from '../components/bonus'
import Discover from '../components/discover'
import Lpart3 from '../components/lpart3'
import Intro from '../components/intro'
import './lp.css'

const lp = (props) => {
    return (
        <div className="lp-container">
            <Helmet>
                <title>NeuroSuccess Bootcamp: Master Your Mind, Master Your Life</title>
                <meta property="og:title" content="Amit World Academy" />
            </Helmet>
            <Navbar81></Navbar81>
            <Hero></Hero>
            <Intro></Intro>
            <Lpart3></Lpart3>
            <Discover></Discover>
            <Bonus></Bonus>
            <Message></Message>
            <Testimonialnpl></Testimonialnpl>
            <FAQ></FAQ>
            <Footer1></Footer1>
        </div>
    )
}
export default lp