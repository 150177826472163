import React from 'react'

import PropTypes, { string } from 'prop-types'

import './testimonialnpl.css'

const Testimonialnpl = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container">
          <h2 className="testimonial-heading thq-heading-2">{props.heading1}</h2>
          <span className="testimonial17-text01 thq-body-small">
            {props.content1}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial17-card">
                <div className="testimonial17-container02">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container03">
                    <strong className="thq-body-large">
                      {props.author1Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author1Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text04 thq-body-small">
                  {props.review1}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container05">
                    <strong className="thq-body-large">
                      {props.author2Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author2Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text07 thq-body-small">
                  {props.review2}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container05">
                    <strong className="thq-body-large">
                      {props.author3Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author3Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text07 thq-body-small">
                  {props.review3}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container05">
                    <strong className="thq-body-large">
                      {props.author4Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text07 thq-body-small">
                  {props.review4}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author5Alt}
                    src={props.author5Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container05">
                    <strong className="thq-body-large">
                      {props.author5Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author5Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text07 thq-body-small">
                  {props.review5}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonialnpl.defaultProps = {
  heading1: 'What Our Clients Say',
  content1: 'Our clients\' success is our success. Hear their stories of overcoming challenges, gaining clarity, and experiencing powerful transformations with us.',
  author1Alt: 'Image',
  author1Src: './author1.jpeg',
  author1Name: 'Lily Pradhan',
  author1Position: '',
  review1: 'I feel blessed for getting the opportunity to learn NLP from Neeta ma\'am. She has a deep understanding of the subject and explains every concept in such a way that it becomes very easy to understand .The techniques in NLP has the power to change your thought process which can transform your life.',
  author2Alt: 'Image',
  author2Src: './author2.jpeg',
  author2Name: 'Gajendra Singh Kushwaha',
  author2Position: '',
  review2: 'I attended Neeta madam\'s mind power course. Before talking to people, I found out that I had low confidence, low self esteem, self doubt and many more things. I have taken thousands of meetings and training on today\'s stage, thank you Neeta madam.',
  author3Alt: 'Image',
  author3Src: './author3.jpeg',
  author3Name: 'मीना सिंह',
  author3Position: '',
  review3: 'मैं मीना सिंह हूं, तीसरी कक्षा तक पढ़ी हूं। नीता मैम के माइंड पावर कोर्स ने मेरा जीवन बदल दिया है। उनकी तकनीकों ने मुझे आत्मविश्वासी लीडर बनाया। उनकी शिक्षा ने दिखाया कि सही मानसिकता से सब कुछ संभव है। मैं इस जीवन बदलने वाले अनुभव के लिए आभारी हूं और इसे हर उस व्यक्ति को सुझाती हूं जो अपनी सीमाओं से मुक्त होना चाहता है।',
  author4Alt: 'Image',
  author4Src: './author4.jpeg',
  author4Name: 'Jayanti Sant',
  author4Position: '',
  review4: 'Attending Neeta Sisodiya Mam\'s Mind Power course has been transformative. Her unique teaching style simplifies complex concepts, helping me unlock my mind\'s potential. The practical exercises have sharpened my focus and clarity, boosting my confidence and empowering me to pursue my goals. I highly recommend this course for anyone seeking to unlock their true potential.',
  author5Alt: 'Image',
  author5Src: './author5.jpeg',
  author5Name:'चंद्रकला राजवाड़े',
  review5: 'मेरा नाम चंद्रकला राजवाड़े है, एक साधारण गृहिणी जो वर्षों से कमर दर्द के कारण बैसाखी पर निर्भर थी। नीता मैडम के माइंड पावर कोर्स ने मुझे विज़ुअलाइजेशन और लॉ ऑफ अट्रैक्शन सिखाया, जिससे मेरी बैसाखी छूट गई। अब मैं गृहिणी से बिजनेस लीडर बन चुकी हूं। उनकी ट्रेनिंग के लिए मैं बेहद आभारी हूं।'

}

Testimonialnpl.propTypes = {
  heading1: PropTypes.string,
  content1: PropTypes.string,
  author1Alt: PropTypes.string,
  author1Src: PropTypes.string,
  author1Name: PropTypes.string,
  author1Position: PropTypes.string,
  review1: PropTypes.string,
  author2Alt: PropTypes.string,
  author2Src: PropTypes.string,
  author2Name: PropTypes.string,
  author2Position: PropTypes.string,
  review2: PropTypes.string,
  author3Alt: PropTypes.string,
  author3Src: PropTypes.string,
  author3Name: PropTypes.string,
  author3Position: PropTypes.string,
  review3: PropTypes.string,
  author4Alt: PropTypes.string,
  author4Src: PropTypes.string,
  author4Name: PropTypes.string,
  author4Position: PropTypes.string,
  review4: PropTypes.string,
}

export default Testimonialnpl
