import React from "react";
import PropTypes from "prop-types";
import './lpart3.css'

const Lpart3 = (props) => {
    return (
        <div className="lpart3 thq-section-padding">
            <div className="lpart3-width thq-section-max-width">
                <div className="lpart3-container">
                    <h2 className="lpart3-h1 thq-heading-2">{props.heading}</h2>
                </div>
                <div className="lpart3-container0">
                    <div className="lpart3-container1">
                        <h3 className="lpart3-h2 thq-heading-3">{props.con1}</h3>
                        <span className="lpart3-text">{props.des1}</span>
                        <h3 className="lpart3-h2 thq-heading-3">{props.con2}</h3>
                        <span className="lpart3-text">{props.des2}</span>
                        <h3 className="lpart3-h2 thq-heading-3">{props.con3}</h3>
                        <span className="lpart3-text">{props.des3}</span>
                        <h3 className="lpart3-h2 thq-heading-3">{props.con4}</h3>
                        <span className="lpart3-text">{props.des4}</span>
                    </div>
                </div>
                <a href="https://forms.gle/Zm7RescMmTAwg2tS8" target="_blank" rel="noopener noreferrer">
                    <button className="intro-button">
                        {props.action}
                    </button>
                </a>
            </div>
        </div>
    )
}

Lpart3.defaultProps = {
    heading: 'WHO IS THIS "ONE-ON-ONE SESSION" FOR?',
    con1: '1. High-Achieving Professionals:',
    des1: 'Individuals aiming for peak performance in their careers but struggling with chronic stress or overwhelm.',
    con2: '2. People Facing Emotional Blocks:',
    des2: 'Those who want to overcome mental and emotional barriers hindering their personal or professional growth.',
    con3: '3. Stress Management Seekers:',
    des3: 'Professionals looking for mindful techniques to manage stress more effectively and avoid burnout.',
    con4: '4. Individuals Striving for Potential:',
    des4: 'People who want to unlock their full potential and perform at their best by enhancing emotional resilience and mental clarity.',
    action: 'Request One on One Session', 
}

Lpart3.propTypes = {
    heading: PropTypes.string,
    con1: PropTypes.string,
    des1: PropTypes.string,
    con2: PropTypes.string,
    des2: PropTypes.string,
    con3: PropTypes.string,
    des3: PropTypes.string,
    con4: PropTypes.string,
    des4: PropTypes.string,
    action: PropTypes.string,
}

export default Lpart3