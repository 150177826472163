import React from 'react'

import PropTypes from 'prop-types'

import './nlp2.css'

const Nlp2 = (props) => {
  return (
    <div
      id="nlp2"
      className="npl2-layout349 thq-section-padding"
    >
      <section id="2" className="npl2-max-width thq-section-max-width">
        <div className="npl2-content">
          <div className="npl2-section-title">
            <div className="npl2-content1">
              <h2 className="thq-heading-2">{props.Title}</h2>
              <p className="thq-body-large">{props.Description}</p>
              <h2 className="npl1-text3 thq-heading-2">{props.cost}</h2>
            </div>
            <div className="npl2-actions">
              <a
                href="https://www.amitworldacademy.com/digital.html"
                target="_blank"
                rel="noreferrer noopener"
                className="npl2-button-ready thq-button-filled"
              >
                <span className="thq-body-small">{props.Action1}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="npl2-image-container">
          <img
            alt={props.ImageAlt}
            src={props.ImageSrc}
            className="npl2-placeholder-image thq-img-ratio-16-9"
          />
        </div>
      </section>
    </div>
  )
}

Nlp2.defaultProps = {
  ImageSrc: 'https://images.unsplash.com/photo-1560185893-a55cbc8c57e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDMzNTcyMXw&ixlib=rb-4.0.3&q=80&w=1080',
  cost: '$200',
  Description: 'Our courses are taught by experts in the fields of real estate, business.',
  Title: 'Expert-Led Courses',
  Action1: 'Enroll Now',
  ImageAlt: 'Expert-Led Courses Image',
}

Nlp2.propTypes = {
  ImageSrc: PropTypes.string,
  cost: PropTypes.string,
  Description: PropTypes.string,
  Title: PropTypes.string,
  Action1: PropTypes.string,
  ImageAlt: PropTypes.string,
}

export default Nlp2
